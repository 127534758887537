import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import {
  CourseSubjectLargeResource,
  MasterCoursesSubjectsGetRequest,
} from '@/store/types'

/**
 * Раздел скорая помощь
 */

@Module({
  dynamic: true,
  name: 'MasterHelp',
  namespaced: true,
  store,
})
class MasterHelp extends VuexModule {
  // ---------------------------- Предметы для скорой помощи ---------------------------- >>
  subjects: CourseSubjectLargeResource[] = []

  @Mutation
  setSubjects (payload: CourseSubjectLargeResource[]) {
    this.subjects = payload
  }

  @Action({ rawError: true })
  async fetchSubjects () {
    const { data } = await MasterCoursesSubjectsGetRequest()
    this.setSubjects(data)
    return data
  }
}

const MasterHelpModule = getModule(MasterHelp)

export default MasterHelpModule
