























import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import HelpCard from '@/components/cards/HelpCard.vue'
import NoDataFound from '@/components/NoDataFound.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import MasterHelpModule from '@/store/modules/master/help'

@Component({
  components: {
    HelpCard,
    NoDataFound,
  },
})
export default class Help extends Mixins(NotifyMixin) {
  private get subjects () {
    return MasterHelpModule.subjects.filter((subject) => subject.ambulanceUrl)
  }

  private created () {
    this.fetchSubjects()
  }

  private fetchSubjects () {
    MasterHelpModule.fetchSubjects()
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Скорая помощь',
    }
  }
}
